import { useState } from 'react';
// import { isMacOs } from 'react-device-detect';
import { Alert, Button, Snackbar } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import AppleIcon from '@mui/icons-material/Apple';

import { ReactComponent as WindowsIcon } from './assets/icons/windows.svg';
import { DEFAULT_REACT_GQL_URI } from './constants';
import SinglePageLayout from './components/common/SinglePageLayout';


const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

const DownloadButton = styled(Button)({
  borderRadius: "16px",
  background: "linear-gradient(90deg, #F8CE52 0%, #F2A348 100%)",
  color: "#321E83",
  padding: "24px 28px",
  fontWeight: 700,
  width: "fit-content",
  margin: "0 20px",
});

const MacOsIcon = styled(AppleIcon)({
  height: '21px',
  marginBottom: '4px',
  marginRight: '4px',
});

const WindowsOsIcon = styled(WindowsIcon)({
  height: '21px',
  width: '21px',
  marginBottom: '4px',
  marginRight: '4px',
});

function Downloads() {
  // const [os, setOs] = useState(isMacOs ? "Mac" : "Windows");
  const [prevPage] = useState();
  const [isSignUpNoticationOpen, setIsSignUpNoticationOpen] = useState<boolean>(prevPage === "signup");
  const [version, setVersion] = useState();

  function getAppVersion() {
    var query = `
      query {
        appVersion
      }
    `;
    
    const uri = process.env.REACT_APP_GQL_API_URI || DEFAULT_REACT_GQL_URI;
    fetch(uri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        query
      })
    }).then(response => {
      if (response.ok) {
        response.json().then(r => {
          if (!r.errors && r.data) {
            setVersion(r.data.appVersion);
          }
        });
      }
    });
  }

  function onSignUpNotificationClose() {
    setIsSignUpNoticationOpen(false);
  }

  getAppVersion();
  const title = <span>Download TableTactic!</span>;
  const description = <span>Enjoy unlimited tabletop games <br /> anytime, anywhere.</span>;
  return (
    <SinglePageLayout title={title} description={description}>
      <ButtonContainer>
        <DownloadButton
          href={`${process.env.REACT_APP_API_SERVER_URI}/game/apps/TableTactic-${version}.dmg`}
        >
          <MacOsIcon /> Download for Mac
        </DownloadButton>
        <DownloadButton
          href={`${process.env.REACT_APP_API_SERVER_URI}/game/apps/TableTactic-${version}.exe`}
        >
          <WindowsOsIcon /> Download for Windows
        </DownloadButton>
      </ButtonContainer>
      <Snackbar
        open={isSignUpNoticationOpen}
        onClose={onSignUpNotificationClose}
      >
        <Alert onClose={onSignUpNotificationClose} severity="success" sx={{ width: '100%' }}>
          Your account is created successfully!
        </Alert>
      </Snackbar>
    </SinglePageLayout>
  );
}

export default Downloads;