/**
 * A singleton class to manage the authentication of the application.
 */
import * as actions from '../store/actions';
import store from '../store/store';

class Auth {
  TOKEN_KEY = 'auth-token';

  /**
   * Logs in using username and password and gets the auth token from the server.
   * @param {String} email The email of the user.
   * @param {String} password The password of the user.
   * @param {Function} successCallback The callback function to be called after login is successful.
   * @param {Function} failureCallback The callback function to be called after login failed.
   */
  login(email, password, successCallback, failureCallback) {
    if (successCallback === undefined) {
      successCallback = () => { };
    }
    if (failureCallback === undefined) {
      failureCallback = () => { };
    }

    // check required fields
    var requiredFields = {
      email,
      password
    };
    var errorFields = {};
    for (let key in requiredFields) {
      if (!requiredFields[key]) {
        errorFields[key] = `Please enter your ${key}.`;
      }
    }

    if (email && email.indexOf('@') < 0) {
      errorFields.email = 'Please enter a valid email.';
    }
    if (Object.keys(errorFields).length > 0) {
      return failureCallback(null, errorFields);
    }

    var query = `query login($emailInput: String!, $passwordInput: String!) {
      logIn(email: $emailInput, password: $passwordInput) {
        nickname
        token
      }
    }`;
    this.sendGqlApi(query, {
      emailInput: email,
      passwordInput: password
    }).then(response => {
      if (!response.ok) {
        failureCallback();
      } else {
        response.json().then(data => {
          if (!this.hasGqlError(response, data, 'Something went wrong, please try refreshing the page', failureCallback)) {
            const user = data.data.logIn;
            localStorage.setItem(this.TOKEN_KEY, user.token);
            store.dispatch(actions.login(email, user.nickname, user.token));
            successCallback();
          }
        });
      }
    });
  }

  /**
   * Logs out by destroying the authentication token.
   * @param {Function} callback Callback function after logout is successful.
   */
  logout(callback) {
    localStorage.removeItem(this.TOKEN_KEY);
    store.dispatch(actions.logout());
    console.log('you are logged out');
    if (callback) {
      callback();
    }
  }

  /**
   * Registers using the user data provided. Send the data to the backend and
   * calls the corresponding callback function after the response is received.
   * @param {String | null} invitationCode
   * @param {String | null} email
   * @param {String | null} username
   * @param {String | null} password
   * @param {String | null} confirmPassword
   * @param {Function} successCallback 
   * @param {Function} failureCallback 
   */
  register(invitationCode, email, username, password, confirmPassword, successCallback, failureCallback) {
    if (successCallback === undefined) {
      successCallback = () => { };
    }
    if (failureCallback === undefined) {
      failureCallback = () => { };
    }
    var errorFields = {};

    // check for invalid fields
    if (!invitationCode) {
      errorFields.invitationCode = 'Please enter your invitation code.';
    }
    if (!email) {
      errorFields.email = 'Please enter your email.';
    } else if (email.indexOf('@') < 0) {
      errorFields.email = 'Please enter a valid email.';
    }
    if (!username) {
      errorFields.username = 'Please enter your username.';
    }
    if (!password) {
      errorFields.password = 'Please enter your password.';
    }
    if (!confirmPassword) {
      errorFields.confirmPassword = 'Please confirm your password.';
    }
    if (password && confirmPassword && password !== confirmPassword) {
      errorFields.confirmPassword = 'Please make sure your password match.';
    }
    if (Object.keys(errorFields).length !== 0) {
      failureCallback(null, errorFields);
      return;
    }

    const query = `
      mutation Register(
        $invitationCode: String!,
        $email: String!,
        $username: String!,
        $password: String!,
      ) {
        createUser(
          newUser: {
            email: $email
            nickname: $username
            password: $password
          },
          invitationCode: $invitationCode
        ) {
          token
        }
      }`;
    this.sendGqlApi(query, {
      invitationCode,
      email,
      username,
      password,
    }).then(response => {
      response.json().then(data => {
        if (!this.hasGqlError(response, data, 'Something went wrong, please try refreshing the page.', failureCallback)) {
          const token = data.data.createUser.token;
          localStorage.setItem(this.TOKEN_KEY, token);
          store.dispatch(actions.login(email, token));
          successCallback();
        }
      });
    });
  }

  /**
   * Send an email to the requested email address to reset user password.
   * @param {string} email The email to retrieve password for.
   * @param {Function} successCallback The callback function to be called after login is successful.
   * @param {Function} failureCallback The callback function to be called after login failed.
   */
  forgotPassword(email, successCallback, failureCallback) {
    if (!email) {
      return failureCallback('Email is required to reset your password.');
    } else if (email.indexOf('@') < 0) {
      return failureCallback('Please enter a valid email address.');
    }

    const query = `
    query ($email: String!) {
     	sendResetPasswordEmail(email:$email)
    }
    `;
    this.sendGqlApi(query, {
      email
    }).then(response => {
      response.json().then(data => {
        if (!this.hasGqlError(response, data, 'Something went wrong, please try again.', failureCallback)) {
          successCallback();
        }
      });
    });
  }

  /**
   * Verify if the given reset password token is valid and get email from it.
   * @param {string} token The reset password token.
   * @param {Function} successCallback The callback function to be called after login is successful.
   * @param {Function} failureCallback The callback function to be called after login failed.
   */
  verifyResetPasswordToken(token, successCallback, failureCallback) {
    if (token === undefined || token === null || token === "") {
      return failureCallback("Token is required to reset password");
    }
    const query = `
    query ($token:String!) {
      getUserFromResetPasswordToken(token:$token) {
        email
      }
    }
    `;
    this.sendGqlApi(query, {
      token
    }).then(response => {
      response.json().then(data => {
        if (!this.hasGqlError(response, data, 'Something went wrong', failureCallback)) {
          successCallback(data.data.getUserFromResetPasswordToken.email);
        }
      });
    });
  }

  resetPassword(email, password, confirmPassword, successCallback, failureCallback) {
    // We assume email is valid here and only validate the password fields
    let fieldErrors = {};
    if (!password) {
      fieldErrors["password"] = "Please enter your new password.";
    }
    if (!confirmPassword) {
      fieldErrors["confirmPassword"] = "Please confirm your password.";
    } else if (password !== confirmPassword) {
      fieldErrors["confirmPassword"] = "Please make sure your password match.";
    }
    if (Object.keys(fieldErrors).length > 0) {
      return failureCallback(null, fieldErrors);
    }

    const query = `
    query ($email:String!, $password:String!) {
      resetPassword(email:$email, password:$password) {
        email
      }
    }
    `;
    this.sendGqlApi(query, {
      email,
      password
    }).then(response => {
      response.json().then(data => {
        if (!this.hasGqlError(response, data, 'Something went wrong. Please try again later.', failureCallback)) {
          successCallback();
        }
      });
    });
  }

  /**
   * Get the authentication token from the local storage and validate
   * it with the server.
   */
  loadAuth() {
    return new Promise((resolve) => {
      const token = localStorage.getItem(this.TOKEN_KEY);
      if (!token) {
        resolve();
        return;
      }

      const query = `
        query getUser($token: String!) {
          user(token:$token ) {
            email
            nickname
          }
        }`
        this.sendGqlApi(query, {
          token
        }).then(response => {
        if (response.ok) {
          response.json().then(r => {
            if (this.hasGqlError(response, r)) {
              localStorage.removeItem(this.TOKEN_KEY);
              resolve();
            } else {
              const user = r.data.user;
              store.dispatch(actions.login(user.email, user.nickname, token));
              resolve();
            }
          });
        }
      });
    });
  }

  sendGqlApi(query, params) {
    return fetch(process.env.REACT_APP_GQL_API_URI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: params
      })
    });
  }

  /**
   * Check if graphql response contains errors and call failure callback to handle it if there's any.
   * 
   * @param {Response} response The response object after a graphql call.
   * @param {Dict} data The data returned from the response.
   * @param {String} defaultMessage Default error message if no error message is found in the response.
   * @param {Function} failureCallback Callback function on failure.
   * @returns returns whether an error occurred.
   */
  hasGqlError(response, data, defaultMessage, failureCallback) {
    var errorMsg;
    var errorFields = {};
    var hasError = false;

    if (data && data.errors) {
      hasError = true;

      for (var error of data.errors) {
        if (error.extensions && error.extensions.wrongField) {
          errorFields[error.extensions.wrongField] = error.message;
        } else {
          errorMsg = error.message;
        }
      }
    }

    if (!response.ok && !errorMsg && Object.keys(errorFields).length === 0) {
      hasError = true;
      errorMsg = defaultMessage;
    }

    if (hasError && failureCallback) {
      failureCallback(errorMsg, errorFields);
    }
    return hasError;
  }

  getUser() {
    return store.getState().user;
  }
}

const instance = new Auth();

export default instance;