import { styled } from '@material-ui/core/styles';
import { LoadingButton } from '@mui/lab';


const Button = styled(LoadingButton)({
    backgroundColor: '#5E84EB',
    borderRadius: '10px',
    color: 'white',
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '18px',
    fontWeight: 700,
    height: '56px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#5E84EB',
    },
    '&.Mui-disabled': {
      backgroundColor: '#5E84EB',
      cursor: 'not-allowed',
      pointerEvents: 'auto',
    },
    '& .MuiLoadingButton-loadingIndicator': {
      color: 'white',
    },
  });
  
  export default Button;