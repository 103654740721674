import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import {
  FormControlLabel,
  Grid,
  InputAdornment,
  IconButton,
  Button as MuiButton,
  Typography
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

import Auth from '../../auth/auth';
import { Button } from '../common';
import AuthLayout from './AuthLayout';
import { 
  AuthFormGroup,
  AuthFormLabel,
  AuthTextField,
  AuthLink,
  AuthCheckbox,
  StyledVisibilitySvg,
  StyledInvisibilitySvg,
} from './AuthObjects';
import FieldHelperText from './FieldHelperText';


const SignUpBox = styled('div')({
  borderTop: '2px solid #BDBEBF',
  marginTop: '32px',
  width: '100%',
});

const SignUpText = styled(Typography)({
  fontFamily: "'Montserrat', sans-serif",
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '28px',
  marginTop: '32px',
  textAlign: 'center',
});

const SignUpButton = styled(MuiButton)({
  border: '2px solid #BDBEBF',
  borderRadius: '10px',
  color: '#636364',
  fontFamily: "'Montserrat', sans-serif",
  fontSize: '18px',
  fontWeight: 700,
  marginTop: '24px',
  height: '64px',
  textTransform: 'none',
});

function SignIn() {
  const navigate = useNavigate();
  // form input
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [rememberMe, setRememberMe] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState();
  const [erroredFields, setErroredFields] = useState({});

  /**
   * Callback function when login succeeeded. Redirect to previous page
   * if there's any, otherwise redirect to homepage.
   */
  function onLoginSuccess() {
    navigate('/downloads');
  }

  /**
   * Callback function when login failed. Display error.
   * @param {String} errorMessage A general error message of the failure.
   * @param {object} erroredFields An object with the error associated with each field.
   */
  function onLoginFailure(errorMessage, erroredFields) {
    setErrorMessage(errorMessage);
    setErroredFields(erroredFields || {});
    setFormLoading(false);
  }

  /**
   * Callback function when field value changes. Update the field value and remove the
   * error of the field.
   * @param {String} field The filed that's updated.
   * @param {String} value The new value of the field.
   */
  function removeErroredField(field) {
    delete erroredFields[field];
    setErroredFields(erroredFields);
  }

  /**
   * When confirm button is clicked, verify the user email and password.
   * @param {Event} event The event for clicking confirm button.
   */
  async function onSubmit(event) {
    event.preventDefault();
    setFormLoading(true);
    Auth.login(email, password, onLoginSuccess, onLoginFailure);
  }

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleRememberMeClicked() {
    setRememberMe(!rememberMe);
  }

  if (Auth.getUser()) {
    return <Navigate to="/downloads" replace />
  }

  return (
    <AuthLayout title="Sign in" error={errorMessage}>
      <AuthFormGroup>
        <AuthFormLabel htmlFor="email">
          Email
        </AuthFormLabel>
        <AuthTextField
          variant="filled"
          fullWidth
          id="email"
          name="email"
          placeholder='Enter your email'
          autoComplete="email"
          autoFocus
          error={erroredFields['email'] !== undefined}
          disabled={formLoading}
          onChange={(e) => {
            setEmail(e.target.value);
            removeErroredField("email");
          }}
        />
        <FieldHelperText id="email-error-text" error>
          {erroredFields.email}
        </FieldHelperText>
      </AuthFormGroup>
      <AuthFormGroup>
        <AuthFormLabel htmlFor="password">
          Password
        </AuthFormLabel>
        <AuthTextField
          variant="filled"
          fullWidth
          name="password"
          type={showPassword ? "text" : "password"}
          id="password"
          placeholder='Enter your password'
          autoComplete="current-password"
          error={erroredFields['password'] !== undefined}
          disabled={formLoading}
          onChange={(e) => {
            setPassword(e.target.value);
            removeErroredField("password");
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
              >
              {
                showPassword ?
                <StyledVisibilitySvg className={erroredFields['password'] === undefined ? undefined : 'error'} /> :
                <StyledInvisibilitySvg className={erroredFields['password'] === undefined ? undefined : 'error'} />
              }
              </IconButton>
            </InputAdornment>
          }
        />
        <FieldHelperText id="password-error-text" error>
          {erroredFields.password}
        </FieldHelperText>
      </AuthFormGroup>
      <Grid container>
        <Grid item container direction="column" xs={6}>
          <Grid item xs>
            {/* TODO: Enable this link when we are ready to support reseting password */}
            <AuthLink href='/forgotPassword'>
              Forgot password?
            </AuthLink>
          </Grid>
          <Grid item xs>
            <FormControlLabel
              control={<AuthCheckbox checked={rememberMe} onChange={handleRememberMeClicked} />}
              label="Remember me" />
          </Grid>
        </Grid>
        <Grid item  xs={6}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            loading={formLoading}
            onClick={onSubmit}
          >
            Sign in
          </Button>
        </Grid>
      </Grid>
      <SignUpBox>
        <SignUpText>Don't have an account?</SignUpText>
        <SignUpButton
          fullWidth
          disabled={formLoading}
          onClick={() => {
            navigate('/signup');
          }}
        >
          Sign up
        </SignUpButton>
      </SignUpBox>
    </AuthLayout>
  );
}

export default SignIn;
