import {
  Checkbox,
  Link,
  OutlinedInput
} from '@material-ui/core';
import InputLabel from '@mui/material/InputLabel';
import { alpha, styled } from '@material-ui/core/styles';

import { ReactComponent as VisibilitySvg } from '../../assets/icons/visibility.svg';
import { ReactComponent as InvisibilitySvg } from '../../assets/icons/invisibility.svg';

const AuthFormGroup = styled('div')({
  marginBottom: '32px',
});

const AuthFormLabel = styled(InputLabel)({
  color: '#262626',
  fontFamily: "'Montserrat', sans-serif",
  fontSize: '18px',
  fontWeight: 'bold',
  lineHeight: '20px',
});

const AuthTextField = styled(OutlinedInput)(({ theme }) => ({
  backgroundColor: '#fbfbfb',
  border: '2px solid #d9d9d9',
  borderRadius: '10px',
  marginTop: '12px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.Mui-focused': {
    backgroundColor: 'transparent',
    boxShadow: `${alpha('#D9D9D9', 0.25)} 0 0 0 4px`,
  },
  '&.Mui-error': {
    border: '2px solid #EB8787',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },

  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiOutlinedInput-input': {
    position: 'relative',
    fontSize: '18px',
    color: '#262626',
    padding: '10px 12px',
    '::placeholder': {
      color: '#bdbebf',
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '18px',
      fontWeight: 'normal',
    },
    '&:disabled': {
      backgroundColor: '#BDBEBF',
    },
  },
}));

const AuthLink = styled(Link)({
  color: '#5E84EB',
  fontFamily: "'Montserrat', sans-serif",
  fontSize: '18px',
  fontWeight: 400,
  marginTop: '16px',
});

const AuthCheckbox = styled(Checkbox)({
  '&.Mui-checked': {
    color: '#30825C',
  }
});

const AuthFooterText = styled('p')({
  fontFamily: "'Montserrat', sans-serif",
  textAlign: 'center',
});

const StyledVisibilitySvg = styled(VisibilitySvg)({
  '&.error': {
    filter: 'brightness(0) saturate(100%) invert(64%) sepia(19%) saturate(958%) hue-rotate(314deg) brightness(95%) contrast(93%)',
  },
});

const StyledInvisibilitySvg = styled(InvisibilitySvg)({
  '&.error': {
    filter: 'brightness(0) saturate(100%) invert(64%) sepia(19%) saturate(958%) hue-rotate(314deg) brightness(95%) contrast(93%)',
  },
});

export {
  AuthFooterText,
  AuthFormGroup,
  AuthFormLabel,
  AuthTextField,
  AuthLink,
  AuthCheckbox,
  StyledVisibilitySvg,
  StyledInvisibilitySvg,
};
