import { Container } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';


const FullScreenContainer = styled(Container)({
  background: "linear-gradient(315deg, #1935AA 0%, #5E84EB 100%)",
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  height: '100%',
  width: '100%',
  maxWidth: '100%',
  overflow: 'scroll',
});

const CenteredContainer = styled('div')({
  margin: 'auto',
  width: 'fit-content',
});

export { FullScreenContainer, CenteredContainer };