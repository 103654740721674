/**
 * Store the user info in the store.
 * @param {Object} state The current state.
 * @param {Object} payload The payload from the logout action.
 */
export function login(state, payload) {
  return {
    ...state,
    user: payload
  }
}

/**
 * Remove the user info from the store.
 * @param {Object} state The current state.
 * @param {Object} payload The payload from the logout action.
 */
export function logout(state, payload) {
  return {
    ...state,
    user: null
  }
}