import { styled } from "@mui/material";
import Logo from "./Logo";
import Footer from "./Footer";

type PropType = {
  title: React.ReactNode,
  description: React.ReactNode,
  decoration?: React.ReactNode,
  children?: React.ReactNode,
};

const PageContainer = styled('div')({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  minHeight: "650px",
  width: "100%",
  background: "linear-gradient(315deg, #1935AA 0%, #5E84EB 100%)",
});

const Content = styled('div')({
  flexGrow: 1,
  width: "70%",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const Title = styled('h1')({
  color: "white",
  fontSize: "68px",
  fontWeight: 700,
  lineHeight: "120%",
  textAlign: "center",
  margin: 0,
  marginBottom: "48px",
});

const Text = styled('p')({
  color: "rgba(255, 255, 255, 0.75)",
  fontWeight: 500,
  fontSize: "40px",
  fontFamily: 'Mona Sans',
  lineHeight: "56px",
  textAlign: "center",
  marginBottom: "72px",
  marginTop: 0,
});

const Description = styled(Text)({
  color: "white",
});

function SinglePageLayout(props: PropType) {
  return (
    <PageContainer>
      <Logo />
      <Content>
        <Title>
          {props.title}
        </Title>
        <Description>
          {props.description}
        </Description>
        {props.children}
      </Content>
      {props.decoration}
      <Footer />
    </PageContainer>
  );
}

export default SinglePageLayout;
