import { Link } from "@material-ui/core";
import { styled } from "@mui/material";
import logoImage from "../../assets/tableTacticLogo.png";

const AppNameContainer = styled('div')({
  fontWeight: 500,
  fontSize: "24px",
  textAlign: "center",
  letterSpacing: "2px",
  padding: 0,
  marginTop: "62px",
});

const AppName = styled('span')({
  color: "white",
  verticalAlign: "super",
});

const LogoImg = styled('img')({
  display: "inline",
  height: "40px",
  width: "40px",
  marginRight: "12px",
});


export default function Logo() {
  return (
    <AppNameContainer>
      <Link href="/" underline="none">
        <LogoImg src={logoImage} />
        <AppName>TableTactic</AppName>
      </Link>
    </AppNameContainer>
  );
}