import { FormHelperText, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { StyledEngineProvider } from '@material-ui/styled-engine';

import { FullScreenContainer, CenteredContainer } from '../pageLayout';
import Logo from '../common/Logo';
import Footer from '../common/Footer';


const MainContainer = styled('div')({
  backgroundColor: 'white',
  backdropFilter: 'blur(4px)',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  margin: ' 72px auto',
  padding: '40px',
  width: '540px',
});

const IconContainer = styled(CenteredContainer)({
  marginBottom: '64px',
});

const Title = styled(Typography)({
  color: '#262626',
  fontFamily: "'Montserrat', sans-serif",
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '42px',
  lineHeight: '59px',
  textAlign: 'center',
});

const FormDescription = styled(FormHelperText)({
  color: '#636364',
  fontFamily: "'Montserrat', sans-serif",
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '28px',
  marginTop: '16px',
});

const FormErrorIcon = styled(ErrorOutlineIcon)({
  color: 'white',
  height: '15px',
  width: '15px',
  marginRight: '4px',
  marginBottom: '-3px',
});

const FormErrorText = styled('div')({
  backgroundColor: '#EB8787',
  borderRadius: '10px',
  color: 'white',
  fontFamily: "'Montserrat', sans-serif",
  marginTop: '30px',
  padding: '24px',
});

const AuthForm = styled('form')({
  marginTop: '48px',
});

type Props = {
  title?: string,
  icon?: JSX.Element,
  description?: JSX.Element | string,
  error?: string,
  children?: JSX.Element | JSX.Element[],
};

function AuthLayout({title, icon, description, error, children }: Props) {
  // title
  let titleElement = null;
  if (title) {
    titleElement = (
      <Title>
        {title}
      </Title>);
  }

  // icon
  let iconElement = null;
  if (icon) {
    iconElement = (
      <IconContainer>
        {icon}
      </IconContainer>
    );
  }

  // description
  let descriptionElement = null;
  if (description) {
    descriptionElement = (
      <FormDescription>
        {description}
      </FormDescription>
    );
  }

  // error
  let errorElement = null;
  if (error) {
    errorElement = (
      <FormErrorText>
        <FormErrorIcon />
        {error}
      </FormErrorText>
    );
  }

  return (
    <StyledEngineProvider injectFirst>
      <FullScreenContainer maxWidth={false}>
        <Logo />
        <CenteredContainer>
          <MainContainer>
            {iconElement}
            {titleElement}
            {descriptionElement}
            {errorElement}
            <AuthForm>
              {children}
            </AuthForm>
          </MainContainer>
        </CenteredContainer>
        <Footer />
      </FullScreenContainer>
    </StyledEngineProvider>
  );
}

export default AuthLayout;
