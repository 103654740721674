import React from 'react';

import Auth from '../../auth/auth';
import { Button } from '../common';
import AuthLayout from './AuthLayout';
import {
  AuthFormGroup,
  AuthFormLabel,
  AuthTextField,
} from './AuthObjects';
import FieldHelperText from './FieldHelperText';
import ResetLinkSent from './ResetLinkSent';


type StateType = {
  email: string,
  error: string,
  emailSent: boolean,
  loading: boolean,
};

type PropType = {
  title: string
  description: string
}

class ForgotPassword extends React.Component<PropType, StateType> {
  submitButton: React.RefObject<HTMLButtonElement>;

  constructor(props: PropType) {
    super(props);
    this.state = {
      // form input
      email: '',
  
      // Error message when submitting the form
      error: '',
      // Whether the email has been sent
      emailSent: false,
      loading: false,
    };

    this.submitButton = React.createRef();
    this.onSuccess = this.onSuccess.bind(this);
    this.onFailure = this.onFailure.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * Callback function when email is sent successfully.
   */
  onSuccess() {
    this.setState({
      loading: false,
      emailSent: true
    });
  }

  /**
   * Callback function when failed to send email. Display error.
   */
   onFailure(errorMessage: string) {
    this.setState({
      loading: false,
      error: errorMessage
    });
  }

  /**
   * When submit button is clicked, send a link to the given email to reset password.
   * @param {Event} event The event for clicking confirm button.
   */
  async onSubmit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    this.setState({loading: true});
    Auth.forgotPassword(this.state.email, this.onSuccess, this.onFailure);
  }

  /**
   * Toggle the disabled status of the given button.
   * @param button The button to be changed.
   */
  setButtonDisabled(button: HTMLButtonElement, disabled: boolean) {
    button.disabled = disabled;
    if (!button.disabled) {
      button.classList.remove("Mui-disabled");
    } else {
      button.classList.add("Mui-disabled");
    }
  }

  /**
   * Callback function when the value changed for email field.
   * @param {String} value: The new value for email.
   */
  onEmailValueChange(value: string) {
    this.setState({
      email: value,
      error: '',
    });
  }

  render() {
    if (this.state.emailSent) {
      return (
        <ResetLinkSent email={this.state.email}/>
      );
    } else {
      return (
        <AuthLayout
          title={this.props.title}
          description={this.props.description}
        >
          <AuthFormGroup>
            <AuthFormLabel htmlFor="email">
              Email
            </AuthFormLabel>
            <AuthTextField
              id="email"
              name="email"
              placeholder="Enter your email"
              autoFocus
              fullWidth
              error={this.state.error.length > 0}
              disabled={this.state.loading}
              onChange={(e) => this.onEmailValueChange(e.target.value)}
            />
            <FieldHelperText id="email-error-text" error>
              {this.state.error}
            </FieldHelperText>
          </AuthFormGroup>
          <AuthFormGroup>
            <Button
              ref={this.submitButton}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={this.state.loading}
              onClick={this.onSubmit}
            >
              Send verification email
            </Button>
          </AuthFormGroup>
        </AuthLayout>
      );
    }
  }
}

export default ForgotPassword;