import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import discord from "../../assets/icons/discord.svg";


const FooterContainer = styled("div")({
  color: "white",
  display: "flex",
  justifyContent: "space-between",
  margin: "48px",
});

const FooterLogo = styled("div")({
  margin: "auto",
  width: "33%",
  textTransform: "uppercase",
});

const ContactIconsContainer = styled("div")({
  display: "flex",
  width: "33%",
  justifyContent: "center",
});

const PlaceHolder = styled("div")({
  width: "33%",
});

function Footer() {
  return (
    <FooterContainer>
      <FooterLogo>©mindhusk llc 2024</FooterLogo>
      <ContactIconsContainer>
        <Link to="https://discord.gg/3zrQXKKkVw" target="_blank" title="discord"><img src={discord} alt="discord icon" /></Link>
      </ContactIconsContainer>
      <PlaceHolder />
    </FooterContainer>
  );
}

export default Footer;