import { FormHelperText, FormHelperTextProps, styled } from '@material-ui/core';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React from 'react';


const HelperText = styled(FormHelperText)({
  color: '#636364',
  fontFamily: "'Montserrat', sans-serif",
  marginTop: '12px',
  '&.Mui-error': {
    color: '#EB8787',
  }
});

const ErrorIcon = styled(ErrorOutlineIcon)({
  color: '#EB8787',
  height: '15px',
  width: '15px',
  marginRight: '4px',
  marginBottom: '-3px',
});

class FieldHelperText extends React.Component<FormHelperTextProps> {
  render() {
    if (this.props.children === undefined || this.props.children === null || this.props.children === "") {
      return null;
    }

    let icon = null;
    if (this.props.error) {
      icon = <ErrorIcon />
    }

    return (
      <HelperText {...this.props}>
        {icon}
        {this.props.children}
      </HelperText>
    )
  }
}

export default FieldHelperText;