import { useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import styled from '@emotion/styled';
import { InputAdornment, IconButton } from "@material-ui/core";

import Auth from '../../auth/auth';
import { Button } from '../common';
import AuthLayout from './AuthLayout';
import {
  AuthFooterText,
  AuthFormGroup,
  AuthFormLabel,
  AuthLink,
  AuthTextField,
  StyledVisibilitySvg,
  StyledInvisibilitySvg,
} from './AuthObjects';
import FieldHelperText from './FieldHelperText';


const SignUpLink = styled(AuthLink)({
  marginLeft: '4px',
});

function SignUp() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [invitationCode, setInvitationCode] = useState(searchParams.get("code") || "");
  const [email, setEmail] = useState(null);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [erroredFields, setErroredFields] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  /**
   * Handler when the signup button is clicked. Register using the form data.
   * @param {MouseEvent} e The event object for the click.
   */
  function onSignUpClick(e) {
    e.preventDefault();
    setFormLoading(true);
    Auth.register(
      invitationCode,
      email,
      username,
      password,
      confirmPassword,
      onRegisterSuccess, 
      onRegisterFailure);
  }

  /**
   * Callback function after registration is successful. Redirect to
   * the homepage.
   */
  function onRegisterSuccess() {
    navigate('/downloads', {state: {prevPage: "signup"}});
  }

  /**
   * Callback function after registration failed. Display error message
   * on the screen.
   * @param {String} errorMessage: An error message to be displayed on the page.
   * @param {Map[String, String]} erroredFields: A list of name of the errored fields.
   */
  function onRegisterFailure(errorMessage, erroredFields) {
    setErrorMessage(errorMessage);
    setErroredFields(erroredFields || {});
    setFormLoading(false);
  }

  if (Auth.getUser()) {
    return <Navigate to='/downloads' replace />;
  }

  return (
    <AuthLayout title="Sign up" error={errorMessage}>
      <AuthFormGroup>
        <AuthFormLabel htmlFor="invitationCode">
          What's your invitation code?
        </AuthFormLabel>
        <AuthTextField
          name="invitationCode"
          id="invitationCode"
          placeholder="Enter your invitation code"
          value={invitationCode}
          fullWidth
          autoFocus={!invitationCode}
          error={erroredFields['invitationCode'] !== undefined}
          disabled={formLoading}
          onChange={(e) => setInvitationCode(e.target.value)}
        />
        <FieldHelperText id="invitation-code-error-text" error>
          {erroredFields['invitationCode']}
        </FieldHelperText>
      </AuthFormGroup>
      <AuthFormGroup>
        <AuthFormLabel htmlFor='email'>What's your email?</AuthFormLabel>
        <AuthTextField
          id="email"
          name="email"
          placeholder='Enter your email'
          fullWidth
          autoFocus={!!invitationCode}
          error={erroredFields['email'] !== undefined}
          disabled={formLoading}
          onChange={(e) => setEmail(e.target.value)}
        />
        <FieldHelperText id="email-error-text" error>
          {erroredFields['email']}
        </FieldHelperText>
      </AuthFormGroup>
      <AuthFormGroup>
        <AuthFormLabel htmlFor='username'>What's your username?</AuthFormLabel>
        <AuthTextField
          id="username"
          name="username"
          placeholder="Enter your username"
          fullWidth
          error={erroredFields['username'] !== undefined}
          disabled={formLoading}
          onChange={(e) => setUsername(e.target.value)}
        />
        <FieldHelperText id="username-error-text" error={erroredFields['username'] !== undefined}>
          {erroredFields['username'] || "This appears on your profile."}
        </FieldHelperText>
      </AuthFormGroup>
      <AuthFormGroup>
        <AuthFormLabel htmlFor='password'>Create your password</AuthFormLabel>
        <AuthTextField
          name="password"
          type={showPassword ? "text" : "password"}
          id="password"
          placeholder='Enter your password'
          fullWidth
          error={erroredFields['password'] !== undefined}
          disabled={formLoading}
          onChange={(e) => setPassword(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={e => setShowPassword(!showPassword)}
              >
              {
                showPassword ?
                <StyledVisibilitySvg className={erroredFields['password'] === undefined ? undefined : 'error'} /> :
                <StyledInvisibilitySvg className={erroredFields['password'] === undefined ? undefined : 'error'} />
              }
              </IconButton>
            </InputAdornment>
          }
        />
        <FieldHelperText id="password-error-text" error>
          {erroredFields['password']}
        </FieldHelperText>
      </AuthFormGroup>
      <AuthFormGroup>
        <AuthFormLabel htmlFor='password'>Confirm your password</AuthFormLabel>
        <AuthTextField
          name="confirmPassword"
          type={showConfirmPassword ? "text" : "password"}
          id="confirmPassword"
          placeholder='Enter your password again'
          fullWidth
          error={erroredFields['confirmPassword'] !== undefined}
          disabled={formLoading}
          onChange={(e) => setConfirmPassword(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle confirm password visibility"
                onClick={e => setShowConfirmPassword(!showConfirmPassword)}
              >
                {
                  showConfirmPassword ?
                  <StyledVisibilitySvg className={erroredFields['confirmPassword'] === undefined ? undefined : 'error'} /> :
                  <StyledInvisibilitySvg className={erroredFields['confirmPassword'] === undefined ? undefined : 'error'} />
                }
              </IconButton>
            </InputAdornment>
          }
        />
        <FieldHelperText id="confirm-password-error-text" error>
          {erroredFields['confirmPassword']}
        </FieldHelperText>
      </AuthFormGroup>
      <AuthFormGroup>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          loading={formLoading}
          onClick={onSignUpClick}
        >
          Sign up
        </Button>
      </AuthFormGroup>
      <AuthFooterText>
        Have an account?
        <SignUpLink href='/signin'>
          Sign in
        </SignUpLink>
      </AuthFooterText>
    </AuthLayout>
  );
}

export default SignUp;