import * as actionTypes from './actionTypes';

export const login = (email, token) => {
  return {
    type: actionTypes.LOGIN,
    payload: {
      email,
      token
    }
  }
}

export const logout = () => {
  return {
    type: actionTypes.LOGOUT
  }
}