import { styled } from "@mui/material";
import diceImage from "./assets/dice.png";
import cardsImage from "./assets/cards.png";
import { Button } from "@material-ui/core";
import SinglePageLayout from "./components/common/SinglePageLayout";

const GetStartedButton = styled(Button)({
  borderRadius: "16px",
  background: "linear-gradient(90deg, #F8CE52 0%, #F2A348 100%)",
  color: "#321E83",
  padding: "24px 28px",
  fontWeight: 700,
  width: "fit-content",
  margin: "0 auto",
});

const DiceImage = styled('img')({
  width: "259px",
  position: "absolute",
  left: "5%",
  top: "25%",
});

const CardImage = styled('img')({
  width: "259px",
  position: "absolute",
  right: "5%",
  top: "27%",
});

function Homepage() {
  const title = (
    <span>
      The tabletop platform <br /> you'll enjoy!
    </span>
  );
  const description = (
    <span>
      TableTactic is an ultimate tabletop platform for <br />
      finding and playing online board game.
    </span>
  );
  const decoration = (
    <span>
      <DiceImage src={diceImage} />
      <CardImage src={cardsImage} />
    </span>
  );
  return (
    <SinglePageLayout title={title} description={description} decoration={decoration}>
      <GetStartedButton href="/signup">Get started</GetStartedButton>
    </SinglePageLayout>
  );
}

export default Homepage;
