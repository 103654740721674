import { useRef, useState } from 'react';
import { useParams } from "react-router-dom";

import AuthLayout from "./AuthLayout";
import {
  AuthFormGroup,
  AuthFormLabel, 
  AuthLink,
  AuthTextField,
  StyledInvisibilitySvg,
  StyledVisibilitySvg
} from './AuthObjects';
import { Button } from '../common';
import { ReactComponent as CheckMarkSvg } from '../../assets/checkmark.svg';
import LoadingIcon from '../../assets/icons/loading.gif';
import Auth from '../../auth/auth';

import FieldHelperText from './FieldHelperText';
import { ForgotPassword } from '.';
import { IconButton, InputAdornment } from '@mui/material';
import { styled } from '@material-ui/core';


const SignInLink = styled(AuthLink)({
  fontSize: '24px',
});

type tokenParam = {
  token: string
}

type resetErrorType = {
  password?: string,
  confirmPassword?: string,
}

function ResetPassword() {
  // init states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [tokenError, setTokenError] = useState("");
  const initFormError: resetErrorType = {};
  const [formError, setFormError] = useState("");
  const [formFieldError, setFormFieldError] = useState(initFormError);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);

  const { token } = useParams<tokenParam>();
  const submitButton = useRef(null);

  // verify token
  const verifyTokenSuccessCallback = (email: string) => {
    setEmail(email);
    setLoading(false);
  };
  const verifyTokenFailureCallback = (error: string) => {
    setTokenError(error);
    setLoading(false);
  };
  if (!token) {
    setTokenError("token is not provided");
  }
  Auth.verifyResetPasswordToken(token!, verifyTokenSuccessCallback, verifyTokenFailureCallback);

  // reset password actions
  const resetSuccessCallback = () => {
    setPasswordChanged(true);
    setSubmitting(false);
  }
  const resetFailureCallback = (errorMessage: string, fieldError: resetErrorType) => {
    setFormFieldError(fieldError);
    setFormError(errorMessage);
    setSubmitting(false);
  };
  const onSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setSubmitting(true);
    Auth.resetPassword(email, password, confirmPassword, resetSuccessCallback, resetFailureCallback);
  }

  // Loading screen
  if (loading) {
    return (
      <AuthLayout
        icon={<img src={LoadingIcon}  alt="loading" />}
      />
    );
  }

  // Token is not valid
  if (tokenError) {
    const errorMessage = tokenError + ". Please enter your email address below to get a new link.";
    return (
      <ForgotPassword
      title="Link invalid"
      description={errorMessage} />
    );
  }

  // Password changed successfully page
  if (passwordChanged) {
    return (
      <AuthLayout
        title="Password updated"
        icon={<CheckMarkSvg />}
        description={
          <p>
            Sweet! Your new password has now been set and you can <SignInLink href="/signin">sign in</SignInLink> now.
          </p>
        }
      />
    );
  }

  // Enter new password page
  return (
    <AuthLayout
      title="Password reset"
      error={formError}
    >
      <AuthFormGroup>
        <AuthFormLabel htmlFor="password">
          New password
        </AuthFormLabel>
        <AuthTextField
          id="password"
          name="password"
          type={showPassword ? 'text' : 'password'}
          placeholder='Enter your new password'
          autoFocus
          fullWidth
          error={formFieldError.password !== undefined}
          disabled={submitting}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={e => setShowPassword(!showPassword)}
              >
              {
                showPassword ?
                <StyledVisibilitySvg className={formFieldError.password === undefined ? undefined : 'error'} /> :
                <StyledInvisibilitySvg className={formFieldError.password === undefined ? undefined : 'error'} />
              }
              </IconButton>
            </InputAdornment>
          }
          onChange={(e) => setPassword(e.target.value)}
        />
        <FieldHelperText id="password-error-text" error>
          {formFieldError.password}
        </FieldHelperText>
      </AuthFormGroup>
      <AuthFormGroup>
        <AuthFormLabel htmlFor="confirmPassword">
          Repeat new password
        </AuthFormLabel>
        <AuthTextField
          id="confirmPassword"
          name="confirmPassword"
          type={showConfirmPassword ? 'text' : 'password'}
          placeholder='Enter your new password again'
          fullWidth
          error={formFieldError.confirmPassword !== undefined}
          disabled={submitting}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle confirm password visibility"
                onClick={e => setShowConfirmPassword(!showConfirmPassword)}
              >
              {
                showConfirmPassword ?
                <StyledVisibilitySvg className={formFieldError.confirmPassword === undefined ? undefined : 'error'} /> :
                <StyledInvisibilitySvg className={formFieldError.confirmPassword === undefined ? undefined : 'error'} />
              }
              </IconButton>
            </InputAdornment>
          }
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <FieldHelperText id="confirm-password-error-text" error>
          {formFieldError.confirmPassword}
        </FieldHelperText>
      </AuthFormGroup>
      <AuthFormGroup>
        <Button
          ref={submitButton}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={submitting}
          onClick={onSubmit}
        >
          Confirm
        </Button>
      </AuthFormGroup>
    </AuthLayout>
  );
}

export default ResetPassword;