import { Navigate, useLocation } from 'react-router-dom';

import Auth from './auth';

function ProtectedRoute({children}) {
  const location = useLocation();

  if (Auth.getUser()) {
    return children;
  } else {
    return <Navigate to="/signin" replace state={{prevPage: location.pathname}} />;
  }
}

export default ProtectedRoute;