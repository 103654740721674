import './app.css';

import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  // Link
} from 'react-router-dom';

import Auth from './auth/auth';
import ProtectedRoute from './auth/protectedRoute';

import Downloads from './downloads';
import Homepage from './homepage';
import { ForgotPassword, ResetPassword, SignIn, SignUp } from './components/auth';
// import User from './components/user';

class App extends Component {
  constructor() {
    super();
    this.state = {
      authLoaded: false
    };
    Auth.loadAuth().then(() => this.setState({ authLoaded: true}));
  }

  render() {
    if (!this.state.authLoaded) {
      return <div/>
    }

    return (
      <Router>
        <Routes>
          <Route exact path='/signin' element={<SignIn />} />
          <Route exact path='/signup' element={<SignUp />}/>
          <Route exact path='/forgotPassword' element={
            <ForgotPassword 
              title="Password reset"
              description="Enter the email that you used to register. We'll send you an email with your username and a link to reset your password." />
          }/>
          <Route exact path='/resetPassword/:token' element={<ResetPassword />}/>

          {/* navigation bar */}
          {/* <nav className='nav'>
            <ul className='nav-ul'>
              <li className='nav-li'><h1 className='logo'>PLAYOR</h1></li>
              <li className='nav-li'>
                <Link to='/' className='nav-link'>Home</Link>
              </li>
              <li className='nav-li'>
                <Link to='/downloads' className='nav-link'>Downloads</Link>
              </li>
            </ul>
            <User/>
          </nav> */}

          {/* <div className="page-content"> */}
            <Route exact path='/downloads' element={
              <ProtectedRoute><Downloads /></ProtectedRoute>
            } />
            <Route exact path='/' element={<Homepage />} />
          {/* </div> */}
        </Routes>
      </Router>
    );
  }
}

export default App;
