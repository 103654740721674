import AuthLayout from "./AuthLayout";
import { ReactComponent as DownloadSvg } from '../../assets/download.svg';


interface Props {
  email: string;
}

function ResetLinkSent({ email }: Props) {
  return (
    <AuthLayout
      title="Check mailbox"
      icon={<DownloadSvg />}
      description="A message has been sent to you by email with instructions on how to reset your password."
    />
  );
}

export default ResetLinkSent;